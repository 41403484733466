import React from "react";
import leftBanner from "../assets/img/left-banner.svg";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const Reservation = () => {
  const defautlValues = {
    fullName: "",
    email: "",
    phone: "",
    agentOrBuyer: "",
    whenToBuyUnit: "",
    property: "",
    comment: "",
  };

  const validateSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required"),
    email: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    agentOrBuyer: Yup.string().required("Please select an option"),
    whenToBuyUnit: Yup.string().required("Please select a date"),
    property: Yup.string().required("Please select an option"),
    comment: Yup.string('Include a valid comment'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: defautlValues,
    resolver: yupResolver(validateSchema),
  });

  const onSubmit = (values) => {
      console.log(values);
      reset(defautlValues);
  };
  return (
    <div className='min-w-screen w-screen h-auto flex justify-center items-center bg-[#D9D9D9]' id='reservation'>
      <div className='w-full h-full grid grid-cols-10'>
        <div className='col-span-10 lg:col-span-4 w-full h-full relative'>
          <img
            src={leftBanner}
            alt='left banner'
            className='absolute top-0 left-0 object-cover w-full h-full'
          />
        </div>
        <div className='col-span-10 lg:col-span-6 bg-[#D9D9D9] pl-[8rem] pr-5 flex flex-col space-y-10 justify-center py-20'>
          <div className='flex flex-col space-y-0'>
            <p className='font-inter text-[24px] leading-[146%] text-[#B56350] uppercase'>
              Get Started
            </p>
            <h2 className='font-orchidea-pro text-[#1D1B1C] text-[48px] leading-[130%] uppercase'>
              Make a reservation
            </h2>
            <p className='font-orchidea-pro font-light text-[#1D1B1C] leading-[140%] text-[24px]'>
              "LIMITED TIME OFFER!" - Be Among the First Few Investors to Double
              their Wealth through the Leonardo Off-Plan & Earn Over 200%
            </p>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col space-y-10 items-start w-full h-auto lg:w-[90%]'
          >
            {/* fullname */}
            <div className='flex flex-col w-full'>
              <input
                {...register("fullName")}
                type='text'
                name=''
                placeholder='Full Name'
                className='font-orchidea-pro font-light text-[20px] leading-[98%] text-[#1D1B1C] px-0 py-2 outline-none bg-transparent border-[1.5px] border-b-[#B56350] border-r-0 border-l-0 border-t-0 w-full'
              />
              {errors.fullName && (
                <div className='text-red-500 text-[13px] py-1'>
                  {errors.fullName.message}
                </div>
              )}
            </div>
            <div className='w-full flex justify-between items-center space-x-10'>
              <div className='flex flex-col w-full'>
                <input
                  {...register("email")}
                  type='email'
                  name=''
                  placeholder='Email'
                  className='font-orchidea-pro font-light text-[20px] leading-[98%] text-[#1D1B1C] py-2 outline-none bg-transparent border-[1.5px] border-b-[#B56350] border-r-0 border-l-0 border-t-0 w-full'
                />
                {errors.email && (
                  <div className='text-red-500 text-[13px] py-1'>
                    {errors.email.message}
                  </div>
                )}
              </div>
              <div className='flex flex-col w-full'>
                <input
                  {...register("phone")}
                  type='text'
                  name=''
                  placeholder='Contact Number'
                  className='font-orchidea-pro font-light text-[20px] leading-[98%] text-[#1D1B1C] py-2 outline-none bg-transparent border-[1.5px] border-b-[#B56350] border-r-0 border-l-0 border-t-0 w-full'
                />
                {errors.phone && (
                  <div className='text-red-500 text-[13px] py-1'>
                    {errors.phone.message}
                  </div>
                )}
              </div>
            </div>
            {/* agent or buyer */}
            <div className='flex flex-col w-full'>
              <select
                {...register("agentOrBuyer")}
                type='text'
                name=''
                placeholder='Email'
                className='font-orchidea-pro font-light text-[20px] leading-[98%] text-[#1D1B1C] py-2 outline-none bg-transparent border-[1.5px] border-b-[#B56350] border-r-0 border-l-0 border-t-0 w-full'
              >
                <option value=''>Are you an agent or a buyer</option>
                <option value='agent'>Agent</option>
                <option value='buyer'>Buyer</option>
              </select>
              {errors.agentOrBuyer && (
                <div className='text-red-500 text-[13px] py-1'>
                  {errors.agentOrBuyer.message}
                </div>
              )}
            </div>
            {/* when are you planning to buy a unit */}
            <div className='flex flex-col w-full'>
              <input
                {...register("whenToBuyUnit")}
                type='date'
                name=''
                placeholder='When are you planning to buy a unit'
                className='font-orchidea-pro font-light text-[20px] leading-[98%] text-[#1D1B1C] py-2 outline-none bg-transparent border-[1.5px] border-b-[#B56350] border-r-0 border-l-0 border-t-0 w-full'
              />
              {errors.whenToBuyUnit && (
                <div className='text-red-500 text-[13px] py-1'>
                  {errors.whenToBuyUnit.message}
                </div>
              )}
            </div>
            {/* property of choice */}
            <div className='flex flex-col w-full'>
              <select
                {...register("property")}
                type='text'
                name=''
                placeholder='Email'
                className='font-orchidea-pro font-light text-[20px] leading-[98%] text-[#1D1B1C] py-2 outline-none bg-transparent border-[1.5px] border-b-[#B56350] border-r-0 border-l-0 border-t-0 w-full'
              >
                <option value=''>Please select your property of choice</option>
                <option value='leonardo'>Leonardo</option>
                <option value='lucrezia'>Lucrezia</option>
              </select>
              {errors.property && (
                <div className='text-red-500 text-[13px] py-1'>
                  {errors.property.message}
                </div>
              )}
            </div>
            {/* leave a comment */}
            <div className='flex flex-col w-full'>
              <input
                {...register("comment")}
                type='text'
                name=''
                placeholder='Leave a comment'
                className='font-orchidea-pro font-light text-[20px] leading-[98%] text-[#1D1B1C] py-2 outline-none bg-transparent border-[1.5px] border-b-[#B56350] border-r-0 border-l-0 border-t-0 w-full'
              />
              {errors.comment && (
                <div className='text-red-500 text-[13px] py-1'>
                  {errors.comment.message}
                </div>
              )}
            </div>
            <button
              className='font-inter font-medium text-[18px] text-[#1D1B1C] border-[1px] border-[#1D1B1C] rounded-full px-20 py-3'
              type='submit'
            >
              Reserve a Unit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Reservation;
