import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { animateScroll } from "react-scroll";
import "./App.css";

import { useNavigate } from "react-router-dom";
import Copyright from "./components/Copyright/Copyright";
import Features from "./components/Features/Features";
import FirstChoice from "./components/FirstChoice";
import Footer from "./components/Footer/Footer";
import NewFooter from "./components/Footer/NewFooter";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import ImageSection from "./components/ImageSection/ImageSection";
import Reservation from "./components/Reservation";
import StickyButton from "./components/StickyButton/StickyButton";
import WhatWeAreBuilding from "./components/WhatWeAreBuilding";

const App = () => {
  // useEffect(() => {
  //     animateScroll.scrollTo(4500, {
  //     smooth: true,
  //     spy: true,
  //   })

  // }, [])

  return (
    <Container fluid className='px-0 flex flex-col'>
      {/* <StickyButton /> */}
      <Header />
      <WhatWeAreBuilding />
      <Features />
      <FirstChoice />
      <Reservation />
      <NewFooter />
      <Copyright />
    </Container>
  );
};

export default App;
